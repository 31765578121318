// atom.js
import { atom } from "recoil";

const lang = localStorage.getItem("lang");
const user = localStorage.getItem("user");

export const alertAtomState = atom({
    key: "isModalOpen",
    default: {
        isOpen: false,
        type: "alert",
        title: "",
        body: "",
        onPress: undefined,
    },
});

export const langAtomState = atom({
    key: "language",
    default: lang || process.env.REACT_APP_LANG,
});

export const userAtomState = atom({
    key: "user",
    default: user
        ? JSON.parse(user)
        : {
              userIp: "",
              uid: "",
              profile_img: "",
              access_token: "",
              subscribe: "",
              nick_name: "",
          },
});
