import React from "react";
import "./assets/css/global.css";
import { RecoilRoot } from "recoil";
import MyRouter from "./routes/index";
import { AppProvider } from "./context/appContext";

function App() {
    return (
        <RecoilRoot>
            <AppProvider>
                <MyRouter />
            </AppProvider>
        </RecoilRoot>
    );
}

export default App;
