import React from "react";
import "./modal.css";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "0",
        left: "50%",
        transform: "translate(-50%)",
        padding: "0",
        width: "100%",
        maxWidth: "37.5rem",
        border: "none",
    },
};

export const ContentText = (props) => {
    return (
        <>
            <p>가나다</p>
        </>
    );
};

const CommonModal = (props) => {
    // const modalRef = useRef();
    const { children } = props;

    return (
        <Modal {...props} shouldCloseOnOverlayClick={true} style={customStyles} ariaHideApp={false}>
            {children}
        </Modal>
    );
};

export default CommonModal;
