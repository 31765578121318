import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { PAGES } from "../constants/routes";
import Intro from "../pages/Intro";

export const pages = [
    { path: PAGES.INTRO, component: <Intro /> },
];

const MainContainer = () => {

    return (
        <Routes>
            {pages.map(({ component, path, exact, child, index }) => {
                return (
                    <Route exact={exact} path={path} element={component} key={`${path}_${index}`}>
                        {child?.map((ch, idx) => (
                            <Route path={ch.path} element={ch.component} key={`${ch.path}_${idx}`} />
                        ))}
                    </Route>
            );
            })}
        </Routes>
    );
};

export default MainContainer;
