import { useState } from "react";
import CommonModal from "../modal/CommonModal";
import "./layout.css";
import DownloadImg from "./../../assets/images/download_icon.svg";
import CloseImg from "./../../assets/images/close_btn_icon.svg";

const MainWrapper = (props) => {
    const { children, setOpenModal, openModal } = props;

    return (
        <div className="main-wrapper-bg">
            <div className="main-wrapper">{children}</div>
            <CommonModal isOpen={openModal}>
                <div className="main-wrapper">
                    <div onClick={() => setOpenModal(false)} className="close-btn">
                        <img src={CloseImg} alt="modal close" />
                    </div>
                    <p className="title caption-02-S">GAME GUIDE</p>
                    <a className="guid-btn-modal" href={"/pdf/(MetaCore)Game.Guide_Korean.pdf"} target="_blank">
                        <img src={DownloadImg} alt={"guide"} />
                        <p className="caption-02-R ">한국어 가이드</p>
                    </a>
                    <a className="guid-btn-modal" href={"/pdf/(MetaCore)Game.Guide_English.pdf"} target="_blank">
                        <img src={DownloadImg} alt={"guide"} />
                        <p className="caption-02-R">English guide</p>
                    </a>
                    <a className="guid-btn-modal" href={"/pdf/(MetaCore)Game.Guide_Chinese.pdf"} target="_blank">
                        <img src={DownloadImg} alt={"guide"} />
                        <p className="caption-02-R">中文导游</p>
                    </a>
                    <a className="guid-btn-modal" href={"/pdf/(MetaCore)Game.Guide_Japanese.pdf"} target="_blank">
                        <img src={DownloadImg} alt={"guide"} />
                        <p className="caption-02-R">日本語ガイド</p>
                    </a>
                </div>
            </CommonModal>
        </div>
    );
};

export default MainWrapper;
