import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./english";
import translationKorea from "./korean";

const resources = {
    KOR: {
        translation: translationKorea,
    },
    ENG: {
        translation: translationEnglish,
    },
};

i18next.use(initReactI18next).init({
    resources,
    lng: process.env.REACT_APP_LANG,
    fallbackLng: process.env.REACT_APP_LANG,
});

export default i18next;
