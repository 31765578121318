import { BrowserRouter } from "react-router-dom";
import MainContainer from "./MainContainer";

const Routes = () => {
    return (
        <BrowserRouter basename="/">
            <MainContainer />
        </BrowserRouter>
    );
};

export default Routes;
