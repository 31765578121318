import React, { useReducer, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import axios from "axios";
import { useRecoilState } from "recoil";
import { langAtomState } from "../stores/atom";

const lang = localStorage.getItem("lang");

const initialState = {
    lang: lang,
};

const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
    const [langState, setLangState] = useRecoilState(langAtomState);
    const { i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLangState(lang);
        localStorage.setItem("lang", lang);
    };

    const initSettingState = () => {
        if (initialState.lang) changeLanguage(initialState.lang);
    };

    useEffect(() => {
        initSettingState();
    }, []);

    const returnValue = {
        changeLanguage,
    };

    return <AppContext.Provider value={returnValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, useAppContext, initialState };
