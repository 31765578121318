import React, { useState, useEffect, useRef } from "react";
import "./intro.css";
import { MainWrapper } from "../../components/Layout";
import TitleImg from "./../../assets/images/metacore_title.png";
import AndroidImg from "./../../assets/images/Android.png";
import AppStoreImg from "./../../assets/images/App-store.png";
import TestflightImg from "./../../assets/images/Testflight.png";
import GooglePlayImg from "./../../assets/images/Google-play.png";
import GuideImg from "./../../assets/images/guide_icon.svg";
// import MetaCoreAPK from "%PUBLIC_URL%/apk/MetaCore.apk";

const IntroPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const alertMessage = 'Plenty Land 서비스 종료 안내 (구 Meta Core)\n2024년 10월 4일부로 Plenty Land 서비스가 종료되었습니다. 그 동안의 성원에 감사 드리며, Plenty 지갑 앱 서비스는 앞으로도 변함 없이 서비스 제공될 예정이오니 많은 이용 부탁 드립니다. 감사합니다!'
    return (
        <MainWrapper openModal={openModal} setOpenModal={setOpenModal}>
            <div className="intro-page">
                <div className="logo-wrap">
                    <img className="logo" src={TitleImg} alt="PlentyLand" />
                </div>
                {process.env.REACT_APP_ENV === "download" && (
                    <a className="core-btn btn-android" onClick={() => alert(alertMessage)}>
                        <img className="core-btn-img" src={AndroidImg} />
                        <div className="button-text-wrap">
                            <div>
                                <p className="caption-02-R">GET IT ON</p>
                                <p className="caption-01-S">Download APK</p>
                            </div>
                        </div>
                    </a>
                )}

                {/* <a className="core-btn btn-google" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.metacore")}> */}
                <a className="core-btn btn-google" onClick={() => alert(alertMessage)}>
                    <img className="core-btn-img" src={GooglePlayImg} />
                    <div className="button-text-wrap">
                        <div>
                            <p className="caption-02-R">GET IT ON</p>
                            <p className="caption-01-S">Google Play</p>
                        </div>
                    </div>
                </a>
                <a className="core-btn btn-apple" onClick={() => alert(alertMessage)}>
                    <img className="core-btn-img" src={AppStoreImg} />
                    <div className="button-text-wrap">
                        <div>
                            <p className="caption-02-R">Download on the</p>
                            <p className="caption-01-S">App Store</p>
                        </div>
                    </div>
                </a>
                {process.env.REACT_APP_ENV === "download" && (
                    <a className="core-btn btn-testFlight" onClick={() => alert(alertMessage)}>
                        <img className="core-btn-img" src={TestflightImg} />
                        <div className="button-text-wrap">
                            <div>
                                <p className="caption-02-R">Download on the</p>
                                <p className="caption-01-S">App Store Beta</p>
                            </div>
                        </div>
                    </a>
                )}

                <button className="guid-btn" onClick={() => alert(alertMessage)}>
                    <img src={GuideImg} alt={"guide"} />
                    <p className="caption-01-R">Game guide</p>
                </button>
            </div>
        </MainWrapper>
    );
};

export default IntroPage;
